
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function BasePhoneNumberInput({ val, setval, err, duplicate, className = '', override = false }: any) {
  return (
    <div className="">
      <PhoneInput
        inputStyle={override ? {} : { backgroundColor: "rgb(243 244 246", border: "none" }}
        buttonStyle={override ? {} : { backgroundColor: "rgb(243 244 246", border: "none" }}
        country={"us"}
        value={val}
        onChange={(num, ccode) => {
          setval(num, ccode);
          err("phone", num, ccode);
        }}
        inputClass={className}
        autoFormat={false}
        countryCodeEditable={false}
        onBlur={duplicate}
      />
    </div>
  );
}

export default BasePhoneNumberInput;
