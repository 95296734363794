import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CalenderIcon,
  CardIcon,
  CloseIcon,
  DolarIcon,
  MinusIcon,
  PlusIcon,
  SuccessfullIcon,
  UserIcon,
} from "../assests/icon/customIcon";
import DialogBox from "./DialogBox";
import { ToastContainer, toast } from "react-toastify";
import aex from "../assests/card_icon/americanexpress.png";
import dc from "../assests/card_icon/dinnersclub.png";
import discover from "../assests/card_icon/discover.png";
import jcv from "../assests/card_icon/jcb.png";
import master from "../assests/card_icon/mastercard.png";
import uatp from "../assests/card_icon/uatp.png";
import union from "../assests/card_icon/union_pay.png";
import visa from "../assests/card_icon/visa.png";
import { apiConstant } from "../contants/apiContants";
import { getReq, postReqJson } from "../services/apiCall";
import Loader from "./Loader";
import { convertTimeStampToDateTime } from "../utils/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { setEventRequireInfo, setSelectTicket } from "../store/features/event/eventSlice";
import Cookies from "js-cookie";
import UserDetailsFrom from "../pages/event/UserDetailsForm";
import { getIsFromGuest, getSelectedTicket } from "../store/features/event/eventSelector";


function TicketPayment(props: any) {
  const { eventDetails, token, eventID } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [formattedDate, setformattedDate] = useState("");
  const navigate = useNavigate();
  const [selectedTicket, setSelectedTicked] = useState({
    id: "",
    amount: null,
    TicketData: {},
  }) as any;
  const [count, setCount] = useState(1);
  const [cardNumber, setCardNumber] = useState("") as any;
  const [cardName, setCardName] = useState("");
  const [cardExpiry, setCardExpiry] = useState("") as any;
  const [cardCVC, setCardCVC] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardNameError, setCardNameError] = useState("");
  const [cardExpiryError, setCardExpiryError] = useState("");
  const [cardCVCError, setCardCVCError] = useState("");
  const [cardType, setCardType] = useState("") as any;
  const [cardLogo, setCardLogo] = useState("") as any;
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existingCard, setExistingCard] = useState([]) as any;
  const [selectedCard, setSelectedCard] = useState({
    id: "",
    type: "new",
    items: {},
  });
  const firstStepBtn = useRef<HTMLButtonElement>()
  const secondStepBtn = useRef<HTMLButtonElement>()

  const [currentStep, setCurrentStep] = useState(1);
  const [totalStep, setTotalStep] = useState(1)
  const dispatch = useDispatch();
  const SelectedTicket = useSelector(getSelectedTicket)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedQueryValue = queryParams.get("selected");
  const FromGuest = useSelector(getIsFromGuest)
  useEffect(() => {
    if (eventDetails?.ticketArray) {
      const selectedIndex = Number(selectedQueryValue) || Number(SelectedTicket) || 0;
      console.log(selectedIndex, "selectedIndex", eventDetails?.ticketArray)
      const item = eventDetails?.ticketArray[selectedIndex];
      setSelectedTicked({
        id: item?._id,
        amount: item?.ticketAdmissionAmount || '0', TicketData: item
      });
    }
  }, [SelectedTicket, eventDetails, selectedQueryValue]);

  useEffect(() => {
    // console.log('eventDetails', eventDetails)
    if (token) {
      getExistingCard();
    }
    let dt = new Date();
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    } as any;
    const finalDate = dt.toLocaleString("en-US", options);
    setformattedDate(finalDate);
  }, [isOpen, token]);

  useEffect(() => {
    if (existingCard?.length > 0) {
      setSelectedCard({
        ...selectedCard,
        id: existingCard[0]?._id,
        type: "old",
        items: existingCard[0],
      });

    }
  }, [existingCard]);

  const handleChangeSelectCard = (e: any, item: any) => {
    setSelectedCard({
      ...selectedCard,
      id: item?._id,
      items: e.target.value,
      type: "old",
    });
    setCardCVCError("");
    setCardNumber("");
    setCardName("");
    setCardCVC("");
    setCardExpiry("");
    setCardLogo("");
  };

  const handleCardNumberChange = (event: any) => {
    let input = event.target.value;
    // Remove any non-digit characters from the input
    const formattedInput = input.replace(/\D/g, "");
    // Split the input into groups of 4 digits
    const groupedInput = formattedInput.match(/.{1,4}/g);
    // Join the groups with a space separator
    const spacedInput = groupedInput ? groupedInput.join(" ") : "";

    setCardNumber(spacedInput);
    if (!validateCardNumber(spacedInput)) {
      setCardNumberError("Invalid card number");
    } else {
      setCardNumberError("");
      let getname = getCardType(input);
      setCardType(getname);
      setCardLogo(getCardLogoUrl(getname));
    }
  };
  const handleCardNameChange = (event: any) => {
    setCardName(event.target.value);
    if (!validateCardName(event.target.value)) {
      setCardNameError("Invalid card name");
    } else {
      setCardNameError("");
    }
  };
  const handleCardExpiryChange = (event: any) => {
    setCardExpiry(event.target.value);
    if (!validateCardExpiry(event.target.value)) {
      setCardExpiryError("Invalid expiry date");
    } else if (checkDateofExpiry(event.target.value)) {
      setCardExpiryError("The card has expired.");
    } else {
      setCardExpiryError("");
    }
  };

  const handleCardCVCChange = (event: any) => {
    setCardCVC(event.target.value);
    if (!validateCardCVC(event.target.value)) {
      if (event.target.value.length > 0) {
        setCardCVCError("Invalid code");
      } else {
        setCardCVCError("cvv is required");
      }
    } else {
      setCardCVCError("");
    }
  };
  const checkDateofExpiry = (val: string) => {
    let month = val.split("/")[0];
    let year = val.split("/")[1];
    year = `20${year}`;
    const expiry = new Date(parseInt(year), parseInt(month) - 1, 0);
    const today = new Date();
    // console.log(val)
    if (expiry > today) {
      //alert('The card has expired.');
      return false;
    }
    return true;
  };

  const validateCardNumber = (cardNumber: any) => {
    //  const regex = /^[0-9]{14,16}$/;
    const cardNumberRegex = /^\d{4}\s?\d{4}\s?\d{4}\s?\d{2,4}$/;
    return cardNumberRegex.test(cardNumber);
  };

  const validateCardName = (cardName: any) => {
    const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    return regex.test(cardName);
  };

  const validateCardExpiry = (cardExpiry: string) => {
    const regex = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;
    return regex.test(cardExpiry);
  };
  const validateCardCVC = (cardCVC: any) => {
    const regex = /^[0-9]{3,4}$/;
    return regex.test(cardCVC);
  };
  const getCardType = (card: any) => {
    const regexVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const regexMastercard = /^5[1-5][0-9]{14}$/;
    const regexAmex = /^3[47][0-9]{13}$/;
    const regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
    const regexDinersClub = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    const regexJCB = /^(?:2131|1800|35\d{3})\d{11}$/;
    const regexUATP = /^1[0-9]{14}$/;
    const regexUnionPay = /^(62|88)\d{14}$/;
    const cardNumber = card.replace(/\s/g, "");
    if (regexVisa.test(cardNumber)) {
      return "visa";
    } else if (regexMastercard.test(cardNumber)) {
      return "mastercard";
    } else if (regexAmex.test(cardNumber)) {
      return "amex";
    } else if (regexDiscover.test(cardNumber)) {
      return "discover";
    } else if (regexDinersClub.test(cardNumber)) {
      return "dinersclub";
    } else if (regexJCB.test(cardNumber)) {
      return "jcb";
    } else if (regexUATP.test(cardNumber)) {
      return "uatp";
    } else if (regexUnionPay.test(cardNumber)) {
      return "unionpay";
    } else {
      return "";
    }
  };
  const getCardLogoUrl = (cardType: any) => {
    let cardTypeName = cardType.toLowerCase();
    switch (cardTypeName) {
      case "visa":
        return visa;
      case "mastercard":
        return master;
      case "amex":
        return aex;
      case "discover":
        return discover;
      case "dinersclub":
        return dc;
      case "jcb":
        return jcv;
      case "uatp":
        return uatp;
      case "unionpay":
        return union;
      default:
        return "";
    }
  };
  const handleClose = () => {
    setIsOpen(!isOpen);
  };
  const handleBacktoHome = () => {
    setIsOpen(false);
    navigate("/");
  };
  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours() as any;
    let minutes = now.getMinutes() as any;
    // Add leading zero if needed
    hours = (hours < 10 ? "0" : "") + hours;
    minutes = (minutes < 10 ? "0" : "") + minutes;
    // console.log(`${hours}${minutes}`)
    return `${hours}${minutes}`;
  };
  const feeOnTicket = (ticketAmount: any) => {
    // YOVI Fee= $1.10 + 3.6% of ticket
    // Stripe Fee = $0.30 + 2.9% of ticket
    ticketAmount = Number(parseFloat(ticketAmount).toFixed(2));
    const appFixedFee = 1.1;
    const appDynamicFee = (ticketAmount * 3.6) / 100.0;
    const stripeFixedFee = 0.3;
    // const stripeDynamicFee = (ticketAmount * 2.9) / 100.0;
    // const totalFixedFee = (
    //     appFixedFee +
    //     appDynamicFee +
    //     stripeFixedFee +
    //     stripeDynamicFee
    // ).toFixed(2);
    // const totalFee =
    //     (Number(totalFixedFee) + parseInt(ticketAmount))
    const stripePercentageFee = 0.029;
    // desiredAmount is the amount we need to get from stripe
    const desiredAmount =
      Number(ticketAmount + Number(appFixedFee + appDynamicFee)) * count;
    let totalFee = (desiredAmount + stripeFixedFee) / (1 - stripePercentageFee);

    const salesTax = eventDetails?.salesTax?.percentage
      ? (eventDetails?.salesTax?.percentage * ticketAmount) / 100
      : 0;
    return Number((totalFee + salesTax).toFixed(2));
  };
  const getFinalAmount = (ticketAmount: any) => {
    ticketAmount = Number(parseFloat(ticketAmount).toFixed(2));
    const appFixedFee = 1.1;
    const appDynamicFee = (ticketAmount * 3.6) / 100.0;
    const stripeFixedFee = 0.3 / count;
    const stripePercentageFee = 0.029;
    // const stripeDynamicFee = (ticketAmount * 2.9) / 100.0;
    const desiredAmount = Number(
      ticketAmount + Number(appFixedFee + appDynamicFee)
    );
    let t1 = (
      (desiredAmount + stripeFixedFee) / (1 - stripePercentageFee) -
      ticketAmount
    ).toFixed(2);
    // (appFixedFee + appDynamicFee + stripeFixedFee + stripeDynamicFee).toFixed(2)
    let t2 = eventDetails?.salesTax?.percentage
      ? ((eventDetails?.salesTax?.percentage * ticketAmount) / 100).toFixed(2)
      : (0).toFixed(2);
    let result = `$${t1} Fee` + (Number(t2) ? `+$${t2} Sales Tax` : ``);
    return result;
  };
  const checkBuyTicketDateRange = (ticket: any) => {
    let obj = {
      start: true,
      end: true,
      dt: "",
    } as any;
    // console.log(ticket)
    if (ticket?.sellStartDateTimeStamp) {
      let dt = new Date(ticket?.sellStartDateTimeStamp);
      if (dt > new Date()) {
        obj.start = false;
        obj.dt = convertTimeStampToDateTime(dt);
        return obj;
      }
    }
    if (ticket?.sellEndDateTimeStamp) {
      let dt = new Date(ticket?.sellEndDateTimeStamp);
      if (dt < new Date()) {
        obj.end = false;
        obj.dt = "";
        return obj;
      }
    }
    return obj;
  };
  const handlePayment = () => {
    if (!token) {
      toast.error(
        "To purchase a ticket, please log in to your account first. Thank you for choosing our service"
      );
    } else if (props.editInfoPopup) return props.openPopup(true);
    else if (eventDetails?.userPurchasedTicketCount && eventDetails?.maxLimit && eventDetails?.maxLimit <= eventDetails?.userPurchasedTicketCount) {
      toast.error("Maximum ticket per user is two only", { toastId: "maxLimit for ticket" })
    }
    else if (selectedTicket?.amount) {
      let purchaseDateRange = checkBuyTicketDateRange(eventDetails);
      const isPromoExist = Cookies.get("aff" + eventID);
      if (purchaseDateRange.end && purchaseDateRange.start) {
        if (
          selectedTicket?.TicketData?.ticketType === 1 &&
          selectedCard.type === "new"
        ) {
          const cardNumberWithoutSpaces = cardNumber.replace(/\s/g, "");
          let data = {
            isSaveCard: isSaveCard ? 1 : 0,
            userType: 1,
            cardNumber: cardNumberWithoutSpaces,
            currentTime: getCurrentTime(),
            ticketAmount: selectedTicket?.amount,
            eventId: eventID,
            ticketId: selectedTicket?.id,
            totalAmount: feeOnTicket(selectedTicket?.amount),
            quantity: count,
            name: cardName,
            cardCvv: cardCVC,
            cardMonth: cardExpiry?.split("/")[0] || "",
            cardYear: cardExpiry?.split("/")[1] || "",
            ticketType: selectedTicket?.TicketData?.ticketType,
            promo: isPromoExist,
          };

          let c = 0;
          if (!cardNumber) {
            c += 1;
            setCardNumberError("card number is required");
          }
          if (!cardName) {
            c += 1;
            setCardNameError("card holder name is required");
          }
          if (!cardExpiry) {
            c += 1;
            setCardExpiryError("card expiry date is required");
          }
          if (!cardCVC) {
            c += 1;
            setCardCVCError("card cvv is required");
          }
          if (selectedTicket?.TicketData?.remainingTicketCapacity < count) {
            c += 1;
            toast.error("All the tickets have been sold out");
          }
          if (
            c === 0 &&
            !cardCVCError &&
            !cardExpiryError &&
            !cardNameError &&
            !cardNumberError
          ) {
            createPayment(data);
          }
        } else if (
          selectedTicket?.TicketData?.ticketType === 1 &&
          selectedCard.type === "old"
        ) {
          let data = {
            isSaveCard: 2,
            userType: 1,
            cardCvv: cardCVC,
            stripeCardId: selectedCard?.id,
            currentTime: getCurrentTime(),
            ticketAmount: selectedTicket?.amount * 1,
            eventId: eventID,
            ticketId: selectedTicket?.id,
            totalAmount: feeOnTicket(selectedTicket?.amount),
            quantity: count,
            ticketType: selectedTicket?.TicketData?.ticketType,
            promo: isPromoExist,
          };

          if (!cardCVC) {
            setCardCVCError("cvv is required");
          } else if (
            selectedTicket?.TicketData?.remainingTicketCapacity < count
          ) {
            toast.error("All the tickets have been sold out");
          } else {
            if (!cardCVCError) {
              createPayment(data);
            }
          }

          // console.log('existing card',data)
        } else {
          let data = {
            isSaveCard: 0,
            userType: 1,
            currentTime: getCurrentTime(),
            ticketAmount: selectedTicket?.amount * 1,
            eventId: eventID,
            ticketId: selectedTicket?.id,
            totalAmount: selectedTicket?.amount * count,
            quantity: count,
            ticketType: selectedTicket?.TicketData?.ticketType,
            promo: isPromoExist,
          };
          if (selectedTicket?.TicketData?.remainingTicketCapacity < count) {
            toast.error("All the tickets have been sold out");
          } else {
            createPayment(data);
          }
        }
      } else {
        let msg = "";
        if (!purchaseDateRange.start) {
          msg = `Ticket booking start from -${purchaseDateRange.dt}`;
        } else if (!purchaseDateRange.end) {
          msg = "Ticket booking closed";
        }
        msg && toast.error(msg);
      }
    } else {
      toast.warning("Please select ticket...");
    }
  };

  const getExistingCard = () => {
    let url = `${apiConstant.BASE_URL}/card/getCard`;
    getReq(url)
      .then((res) => {
        //  console.log('res event list::;', res)
        if (res?.data?.statusCode) {
          let data = res?.data?.responseData?.response;
          // console.log('get card res::;', data)
          setExistingCard(data);
        } else {
          let msg = res?.data?.error?.responseMessage;
          toast.error(msg);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Ack, sorry! We’re having a server issue. Try again later");
      });
  };
  const createPayment = (data: any) => {
    setLoading(true);
    let url = `${apiConstant.BASE_URL}/payment/createPayment`;
    postReqJson(url, data)
      .then((res) => {
        //  console.log('res event list::;', res)
        setLoading(false);
        if (res?.data?.statusCode) {
          let data = res?.data?.responseData?.response;
          // console.log('create payment res::;', data)
          toast.success(data?.message);
          setIsOpen(true);
          setCardNumber("");
          setCardName("");
          setCardCVC("");
          setCardExpiry("");
          setCardLogo("");
          Cookies.remove("aff" + eventID);
          Cookies.remove("affs" + eventID);
        } else {
          let msg = res?.data?.error?.responseMessage;
          toast.error(msg);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error("Ack, sorry! We’re having a server issue. Try again later");
      });
  };
  useEffect(() => {
    if (!token && props.editInfoPopup) {
      dispatch(setEventRequireInfo(true));
      setTotalStep(3)
      return;
    } else dispatch(setEventRequireInfo(false));
    if (token && props.editInfoPopup) {
      setCurrentStep(2)
      setTotalStep(3)
    }
    if (!token && !props.editInfoPopup) {
      setCurrentStep(1)
      setTotalStep(2)
    }
    else {
      setCurrentStep(1)
      setTotalStep(1)
    }
    console.log(props.editInfoPopup, "props.editInfoPopup")
  }, [eventDetails, props.editInfoPopup]);

  const myDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollToBottom = () => {
      if (myDivRef.current) {
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Scroll to the bottom after the component has mounted and loading is complete
    const timeoutId = setTimeout(() => {
      if (totalStep === currentStep && FromGuest == true) {
        scrollToBottom();
      }
    }, 20);

    // Clear the timeout if the component unmounts before the delay
    return () => clearTimeout(timeoutId);
  }, [FromGuest]);



  return (
    <div>
      <p className="pb-2 text-1xl md:text-xl font-semibold md:pb-8">
        {" "}
        Ticket Price{" "}
      </p>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {eventDetails?.ticketArray?.map((item: any, i: any) => (
            <div key={i} className="w-full pb-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div className="text-gray-600">
                    {" "}
                    <DolarIcon />{" "}
                  </div>
                  <div className="ml-8">
                    <p className="w-full text-ellipsis">{item?.ticketTitle} </p>
                    <p className="text-gray-500 w-full text-ellipsis">
                      {item?.description}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="">
                    {item?.ticketType === 1 || item?.ticketAdmissionAmount
                      ? `$${item?.ticketAdmissionAmount?.toFixed(2)}`
                      : "Free"}{" "}
                  </p>
                  <input
                    type="radio"
                    name="p"
                    checked={selectedTicket?.id === item?._id}
                    className="h-[20px] w-[20px] ml-8"
                    value={
                      item?.ticketType === 1 && item?.ticketAdmissionAmount
                        ? item?.ticketAdmissionAmount
                        : 0
                    }
                    onChange={(e) => {
                      setSelectedTicked({
                        id: item?._id,
                        amount: e.target.value,
                        TicketData: item,
                      });
                      dispatch(setSelectTicket(i))
                      // toast.warn('please make sure you sign up or login at the top before you complete your transaction', { toastId: 'uniqueToastId' })
                    }}
                  />
                </div>
              </div>
              {selectedTicket?.id === item?._id && (
                <div className="flex justify-between ml-4 md:ml-12 mt-4 items-center">
                  <div className="flex gap-4">
                    <div
                      onClick={() => setCount(count === 1 ? count : count - 1)}
                      className="cursor-pointer"
                    >
                      {" "}
                      <MinusIcon />
                    </div>
                    <div> {count < 10 ? `0${count}` : count} </div>
                    <div
                      onClick={() => {
                        if (count >= (eventDetails?.maxLimit - eventDetails?.userPurchasedTicketCount)) {
                          toast.error("Maximum ticket per user is two only.", { toastId: 'maxLimit for ticket' })
                          return
                        }
                        if (eventDetails._id === "660ae4247b372300121700d4" && count >= 2) {
                          toast.error("Maximum ticket per user is two only.", { toastId: 'maxLimit for ticket' })
                          return
                        }
                        setCount(count + 1)
                      }}
                      className="cursor-pointer"
                    >
                      <PlusIcon />{" "}
                    </div>
                  </div>

                  {item?.ticketType === 1 && (
                    <div className="text-sm text-gray-500 mr-4 md:mr-12">
                      {" "}
                      {getFinalAmount(item?.ticketAdmissionAmount)}{" "}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
          {selectedTicket?.amount &&
            <UserDetailsFrom selectedTicketIndex={SelectedTicket} firstStepBtn={firstStepBtn} secondStepBtn={secondStepBtn} currentStep={currentStep} totalStep={totalStep} setCurrentStep={setCurrentStep} />

            // currentStep === 1 && totalStep > 1 ? <UserDetailsForm />
            //   : currentStep === 2 && totalStep > 2 ? <DemographicForm /> : ''
          }
          {totalStep === currentStep ? selectedTicket?.amount && selectedTicket?.amount !== "0" && (
            <div className="" ref={myDivRef}>

              <p
                id="payment-title"
                className="py-2 text-1xl md:text-2xl md:py-4"
              >
                {" "}
                Payment Details{" "}
              </p>
              {existingCard?.map((item: any, i: any) => (
                <div key={i}>
                  <div className="flex pb-2 gap-4 items-center">
                    <input
                      type="radio"
                      value={item?._id}
                      name="card"
                      checked={selectedCard?.id === item?._id}
                      onChange={(e) => handleChangeSelectCard(e, item)}
                      className="h-[20px] w-[20px] "
                    />
                    <img
                      src={getCardLogoUrl(item?.brand)}
                      alt="card type"
                      className="w-[60px] h-[30px]"
                    />
                    <div className="">
                      <p className="">{item?.name}</p>
                      <p className="text-gray-600">
                        {" "}
                        **** **** **** {item?.last4}
                      </p>
                    </div>
                  </div>
                  {selectedCard?.id === item?._id && (
                    <div className="flex justify-center flex-wrap items-center mb-3">
                      <input
                        type="text"
                        id="cardExpiry"
                        placeholder="CVV"
                        value={cardCVC}
                        onChange={handleCardCVCChange}
                        className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-[100px]"
                      />
                      {cardCVCError && (
                        <div className="text-red-600 text-sm ml-2">
                          {cardCVCError}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
              <div className="flex pb-2 gap-4 items-center my-2">
                <input
                  type="radio"
                  value="new"
                  name="card"
                  checked={selectedCard?.type === "new"}
                  className="h-[20px] w-[20px] "
                  onChange={(e) =>
                    setSelectedCard({
                      ...selectedCard,
                      id: "",
                      items: {},
                      type: "new",
                    })
                  }
                />
                <p className="">Enter Card Details </p>
              </div>
              {selectedCard?.type === "new" && (
                <div>
                  <div className="mb-2">
                    <label htmlFor="cardNumber" className="text-gray-500">
                      Enter card number
                    </label>
                    <div className="relative flex max-w-[300px] bg-gray-100 items-center">
                      <div className="z-50 pl-4">
                        <CardIcon />
                      </div>
                      <input
                        type="text"
                        id="cardNumber"
                        value={cardNumber}
                        placeholder="Enter card number"
                        onChange={handleCardNumberChange}
                        className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full"
                      />
                      <div className="absolute right-2 top-1 z-50">
                        {cardLogo && (
                          <img
                            src={cardLogo}
                            alt="card type"
                            className="w-[60px] h-[30px]"
                          />
                        )}
                      </div>
                    </div>
                    {cardNumberError && (
                      <div className="text-red-600 text-sm">
                        {cardNumberError}
                      </div>
                    )}
                  </div>
                  <div className="mb-2 relative">
                    <label htmlFor="cardName" className="text-gray-500">
                      Enter card holder name
                    </label>{" "}
                    <br />
                    <div className="relative flex max-w-[300px] bg-gray-100 items-center">
                      <div className="z-40 pl-4">
                        <UserIcon />
                      </div>
                      <input
                        type="text"
                        id="cardName"
                        placeholder="Enter card holder name"
                        value={cardName}
                        onChange={handleCardNameChange}
                        className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full"
                      />
                    </div>
                    {cardNameError && (
                      <div className="text-red-600 text-sm">
                        {cardNameError}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-wrap max-w-[280px] justify-between mb-2">
                    <div className="w-[50%]">
                      <label htmlFor="cardExpiry" className="text-gray-500">
                        Enter expiry date
                      </label>
                      <div className="relative flex bg-gray-100 items-center">
                        <div className="z-50 pl-4">
                          <CalenderIcon />
                        </div>
                        <input
                          type="text"
                          id="cardExpiry"
                          placeholder="02/28"
                          value={cardExpiry}
                          onChange={handleCardExpiryChange}
                          className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full"
                        />
                      </div>
                      {cardExpiryError && (
                        <div className="text-red-600 text-sm">
                          {cardExpiryError}
                        </div>
                      )}
                    </div>
                    <div className="w-[40%]">
                      <label htmlFor="cardCvv" className="text-gray-500">
                        Enter cvv
                      </label>
                      <div className="relative flex bg-gray-100 items-center">
                        <div className="z-50 pl-4">
                          <CardIcon />
                        </div>
                        <input
                          type="text"
                          id="cardCvv"
                          placeholder="***"
                          value={cardCVC}
                          onChange={handleCardCVCChange}
                          className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full"
                        />
                      </div>
                      {cardCVCError && (
                        <div className="text-red-600 text-sm">
                          {cardCVCError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-3 flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={isSaveCard ? true : false}
                      onChange={(e) => setIsSaveCard(e.target.checked)}
                      className="h-[20px] w-[20px] bg-sky-500"
                    />
                    <p className="text-gray-500">
                      {" "}
                      Save card for quick payments in future
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : ''}
          {totalStep > 1 && selectedTicket.amount ? <div className="mt-5 flex justify-between items-center gap-x-8">
            {totalStep > 1 && currentStep > 1 ? <button type="button" onClick={() => {

              setCurrentStep(currentStep - 1)
            }} className="py-2 px-4 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" data-hs-stepper-back-btn>
              <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
              Back
            </button> : ""}
            <button
              className="text-white px-4 py-2 justify-center  w-full inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border bg-sky-600 hover:bg-sky-500  disabled:bg-gray-400"
              onClick={() => {
                if (currentStep === totalStep) return handlePayment()
                if (currentStep === 1) {
                  if (firstStepBtn.current?.disabled) return
                  firstStepBtn.current?.click()
                  // setCurrentStep(2)
                }
                if (currentStep === 2) {
                  console.log(secondStepBtn.current?.disabled, "secondStepBtn.current?.disabled")
                  if (secondStepBtn.current?.disabled) return
                  secondStepBtn.current?.click()
                  // setCurrentStep(3)
                }
              }}
              type="submit"> {currentStep !== totalStep ? 'Next' : selectedTicket?.amount && selectedTicket?.amount !== "0"
                ? `Pay  $${feeOnTicket(selectedTicket?.amount)}`
                : "Checkout"}</button>
          </div>
            :
            <button
              // disabled={!token}
              onClick={handlePayment}
              className="text-white px-4 py-2 w-full bg-sky-600 hover:bg-sky-500 rounded-md  mt-4 md:mt-8 font-bold disabled:bg-gray-400"
            >
              {selectedTicket?.amount && selectedTicket?.amount !== "0"
                ? `Pay  $${feeOnTicket(selectedTicket?.amount)}`
                : "Checkout"}
            </button>}
        </div>
      )}
      {isOpen && (
        <DialogBox isOpen={isOpen} onClose={handleClose}>
          <div className="w-full text-center">
            <div className="flex justify-center flex-col items-center">
              <SuccessfullIcon />
              <p className="text-1xl md:text-2xl font-bold py-2">
                {" "}
                Successfully Booked
              </p>
              <p className="text-gray-600">
                {" "}
                On <span className="ml-1"> {formattedDate}</span>{" "}
              </p>
              <p className="text-gray-600">
                {" "}
                *Ticket will be sent to your register phone number and email{" "}
              </p>
            </div>
            <button
              onClick={handleBacktoHome}
              className="w-[90%] md:w-[80%] px-4 py-2 rounded-md text-white bg-sky-600 hover:bg-sky-500 my-6 md:my-8"
            >
              Back to Home
            </button>
          </div>
        </DialogBox>
      )}

      <ToastContainer />
    </div>
  );
}

export default TicketPayment;
